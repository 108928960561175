import React from 'react';

// Components
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Column from '../../components/Column';
import PreHeading from '../../components/PreHeading';
import TrustBox from '../../components/TrustBox';

// Icons
import childImage from '../../images/get-compensated-flight-delayed.png';
import checkIcon from '../../images/check-icon.svg';
import victoryIcon from '../../images/victory-icon.svg';
import dealIcon from '../../images/we-deal-icon.svg';
import successIcon from '../../images/success-icon.svg';
import teamIcon from '../../images/team-icon.svg';
import flightDataIcon from '../../images/flight-data-icon.svg';
import supportIcon from '../../images/online-support-icon.svg';
import arrowRight from '../../images/chev-right.svg';

// Logos
import vluchtVertraagd from '../../images/VLUCHT-VERTRAAGD.NL.png';
import flugVerspaetet from '../../images/FLUG-VERSPAETET.DE.png';
import volRRetarde from '../../images/VOL-RETARDÉ.FR.png';
import flightDelayed from '../../images/FLIGHT-DELAYED.CO.UK.png';
import FooterJumbotron from "../../components/FooterJumbotron";

const HomePage = () => {

  return (
    <>
      <section className="container w-full mx-auto mdd:max-w-none h-400 md:h-400 sm:h-422 lg:h-422">
        <div className="header-section lg:items-start sm:mx-auto flex flex-col md:flex-row items-center lg:justify-between">
          <div
            className="mt-29 w-full sm:w-auto flex-shrink-0 flex align-center
            lg:items-start items-center flex-col lg:max-w-header-1 lg:text-left text-center"
          >
            <h1 className="max-w-300 md:max-w-540">
              Flight delayed or cancelled? Get compensated!
            </h1>
            <p className="mb-35 mt-12 md:text-2xl max-w-300 md:max-w-708 text-lg sm:leading-normal">
              Check your flight and claim your refund or a compensation of up to €600 per person!
            </p>
            <Button
                theme="primary"
                showIcon={false}
                width="auto"
                tag="a"
                href="https://claim.flight-delayed.com/"
                target="_blank"
            >
              Claim compensation
            </Button>
            <TrustBox
              className="mt-20 lg:-ml-25"
              id="#trustpilot-widget"
              locale="en-US"
              template="5419b6ffb0d04a076446a9af"
              business="5bc5f3e91ddb0e00014fc0ba"
              height="26px"
              width="464px"
              theme="light"
              stars="4, 5"
            />
          </div>
          <div className="sm:w-auto mt-16 hidden lg:block">
            <img
              src={childImage}
              alt="Green Claim Logo"
              width={419}
              height={406}
            />
          </div>
        </div>
      </section>
      <section className="first-section text-center lg:pt-100 md:pt-70 pt-55 relative">
        <div className="container w-full mx-auto mdd:max-w-none">
          <PreHeading>
            3 easy steps
          </PreHeading>
          <h2 className="lg:max-w-570 md:max-w-575 mx-auto pt-12">
            How we claim your flight refund or compensation
          </h2>
          <div className="flex flex-col lg:text-center text-left lg:flex-row sm:items-start sm:align-center justify-evenly mt-50">
            <Column
              icon={checkIcon}
              title={"Check your flight"}
              description={"Tell us more about your flight and find out what you're entitled to. This will only take a couple of seconds!"}
              iconClass="lg:mx-auto mr-15"
              wrapperClassName="lg:max-w-300"
            />
            <Column
              icon={dealIcon}
              title={"We deal with the airline"}
              description={"Our team of experts will assess your legal position to ensure that you receive the compensation or refund to which you are legally entitled."}
              iconClass="lg:mx-auto mr-5"
              wrapperClassName="lg:max-w-300"
            />
            <Column
              icon={victoryIcon}
              title={"No win, no fee"}
              description={"Our success fee only applies when the airline pays out the refund or compensation. All risks and legal costs are covered by us!"}
              iconClass="lg:mx-auto"
              wrapperClassName="lg:max-w-300"
            />
          </div>
        </div>
      </section>
      <section className="second-section text-center relative">
        <div className="container w-full mx-auto mdd:max-w-none relative">
          <h2 className="text-white lg:max-w-915 mx-auto">
            With over 10 years of experience we supported more than 5 million passengers before you
          </h2>
          <div className="flex items-start align-center items-center justify-evenly mt-50">
            {/* <ReviewCard
              wrapperClassName="block"
              icon={starsIcon}
              title={"They are amazing"}
              description={"They are amazing, it took almost no effort from me and I'm impressed by how easy the process was."}
              iconClass="mx-auto"
              wrapperClassName="sm:hidden"
            />
            <ReviewCard
              icon={starsIcon}
              title={"They took care of everything"}
              description={"Honestly, this was great service. I barely did anything but gave them all the information."}
              iconClass="mx-auto"
            />
            <ReviewCard
              icon={starsIcon}
              title={"Good company"}
              description={"It took quite sometime to get my compensation but in the end it was worth it."}
              iconClass="mx-auto"
              wrapperClassName="sm:hidden"
            /> */}
            <TrustBox 
              className="w-full"
              id="#trustpilot-widget"
              locale="en"
              template="54ad5defc6454f065c28af8b"
              business="62160cfa10aed4d5536d375f"
              height="247px"
              width="100%"
              theme="dark"
              stars="4, 5"
              style={{ position: 'relative' }}
            />
          </div>
          <div className="mt-40">
            <a
              className="text-lg font-pt-sans leading-normal font-semibold text-white"
              href="https://uk.trustpilot.com/review/www.flight-delayed.com"
              target="_blank"
              rel="noreferrer"
            >
              Read all reviews
              <Icon src={arrowRight} className="ml-15 inline-block" />
            </a>
          </div>
        </div>
      </section>
      <section className="third-section relative">
        <div className="container w-full mx-auto mdd:max-w-none relative">
          <div className="flex xl:flex-row flex-col items-start align-center justify-between">
            <div className="xl:max-w-450 p-0 md:p-25 text-center sm:mb-40 lg:text-left">
              <PreHeading>
                ABOUT Yource
              </PreHeading>
              <h2 className="xl:max-w-400 pt-12">
                We are here to empower travelers
              </h2>
              <p className="left-column text-base lg:text-2xl pt-20">
                Back in 2010, when our founders saw that passengers with a delayed, cancelled or overbooked flight were being left in the cold and their rights ignored, they decided it was time to take action! For over 10 years now, we empower the rights of passengers all over Europe.
              </p>
            </div>
            <div className="xl:max-w-610 p-0 md:p-25">
              <div className="flex flex-col lg:flex-row items-start align-center xl:space-x-50">
                <Column
                  icon={teamIcon}
                  title={"Expert legal team"}
                  description={"We have an experienced legal team that can assist you. They can determine the best course of action to book success."}
                  iconClass="mr-1"
                />
                <Column
                  icon={successIcon}
                  title={"Success rate 98%"}
                  description={"Our team of experts will start working on your claim right away. We have a success rate of 98% in court."}
                  iconClass="mr-16"
                />
              </div>
              <div className="flex flex-col lg:flex-row items-start align-center xl:mt-45 xl:space-x-50">
                <Column
                  icon={supportIcon}
                  title={"Online support"}
                  description={"Our team is here to answer all your questions and to ensure a successful outcome of your claim."}
                />
                <Column
                  icon={flightDataIcon}
                  title={"Flight & weather data"}
                  description={"We collect data for millions of flights and weather data from all around the globe. This information helps us with the assessment of your claim."}
                  iconClass="mr-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="fourth-section text-center relative">        
        <div className="container mx-auto w-full mdd:max-w-none relative pt-75">
          <PreHeading>
            OUR WEBSITES
          </PreHeading>
          <h2>
            Helping passengers all over Europe
          </h2>
          <div className="flex items-start align-center items-center justify-between mt-50">
            <img
              src={vluchtVertraagd}
              alt="Green Claim Logo"
              width={193}
              height={76}
            />
            <img
              src={flugVerspaetet}
              alt="Green Claim Logo"
              width={193}
              height={76}
            />
            <img
              src={volRRetarde}
              alt="Green Claim Logo"
              width={193}
              height={76}
            />
            <img
              src={flightDelayed}
              alt="Green Claim Logo"
              width={193}
              height={76}
            />
          </div>
        </div>
      </div>
      <FooterJumbotron />
    </>
  );
};

export default HomePage;
