import React, { useEffect, useRef } from 'react';

const TrustBox = (props) => {
  const { className, theme, height, width, template, business, locale, id, stars, style= {} } = props;

  const ref = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined" && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      id={id}
      className={`trustpilot-widget ${className}`}
      data-businessunit-id={business}
      data-template-id={template}
      data-theme={theme}
      data-locale={locale}
      data-style-height={height}
      data-style-width={width}
      data-stars={stars}
      style={style}
    >
      <a href="https://uk.trustpilot.com/review/www.flight-delayed.com" target="_blank" rel="noreferrer" >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;