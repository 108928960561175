import React from 'react';

const Column = ({ icon, alt, title, description, wrapperClassName = "", iconClass = "", className = "" }) => (
  <div className={`flex items-start align-center items-center flex-1 justify-evenly mb-40 xl:mb-0 lg:block ${wrapperClassName}`}>
    <img
      src={icon}
      alt={alt}
      className={`${iconClass} self-start mb-20`}
    />
    <div className={`flex flex-col ml-20 lg:ml-0 ${className}`}>
      <h3>
        {title}
      </h3>
      <p className="text-root lg:mt-15 mt-5">
        {description}
      </p>
    </div>
  </div>
);

export default Column;